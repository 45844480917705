import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  Pagination,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { Check, Search } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
// import PrintIcon from "@mui/icons-material/Print";
// import ReplayIcon from '@mui/icons-material/Replay';
import UserPng from "../Assets/user.png";
import { apiUrl } from "../Pages/Config";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import cameraImg from "../Assets/camera.png";
import Chip from "@mui/joy/Chip";
import { ListItemDecorator } from "@mui/joy";
import ListItem from "@mui/joy/ListItem";
import CardActions from "@mui/joy/CardActions";
import PersonIconPng from "../Assets/user.png";
import tickPng from "../Assets/tick.png";
import nameIcon from "../Assets/nameIcon.svg";
import emailIcon from "../Assets/emailIcon.svg";
import numberIcon from "../Assets/numberIcon.svg";
import companyIcon from "../Assets/companyIcon.svg";
import userCountIcon from "../Assets/userCountIcon.svg";
import printIcon from "../Assets/printIcon.svg";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
const getData = async (
  setProfileData,
  setproductData,
  activeProductData,
  setActiveProductData,
  setInvoiceData,
  setPlanDetailsArray,
  setRenewActive
) => {
  const logId = localStorage.getItem("Id");
  const accessToken = localStorage.getItem("accessToken");
  // console.log("Hai i am running from function...",logId);
  if (logId && accessToken) {
    try {
      const response = await fetch(apiUrl + "getSspUserDetails/" + logId+ '?productname=FrothCRM', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const myAccountData = await response.json();
     // console.log('res--',myAccountData.customerDetails);
      const productActive = Object.values(myAccountData.productDetails)
        .filter((productDetails) => productDetails.status === "Active")
        .map((productDetails, index) => ({
          productDetails,
        }));
    //  console.log('resAct--',productActive[0].productDetails);
      setProfileData(myAccountData.customerDetails);
      setActiveProductData(productActive[0].productDetails);
      
      
      const productData = Object.values(myAccountData.productDetails)
        .filter((productDetails) => productDetails.status != "Active")
        .map((productDetails, index) => ({
          s_no: index + 1,
          plan: productDetails.plantype,
          s_date: formatDate(productDetails.purchase_date),
          e_date: formatDate(productDetails.due_date),
          users: productDetails.number_of_users,
          status: productDetails.status
        }));
      
      
      setproductData(productData);

      const planDetailsArray = productActive[0].productDetails.plan_details
        .split("|")
        .map((detail) => detail.trim());
      setPlanDetailsArray(planDetailsArray);
      // console.log('myAccountData-',myAccountData);
      
      const invoiceDataArr = Object.values(
        myAccountData.customerInvoiceDetails
      ).map((invoice, index) => ({
        S_No: index + 1,
        date: formatDate(invoice.invoiceDate),
        Invoice: invoice.invoiceNumber, // You can customize the invoice number format
        plan: invoice.planType,
        price: invoice.totalAmt,
        invoicePdfUrl: invoice.invoicePdfUrl,
      }));
      setInvoiceData(invoiceDataArr);
      // console.log('activeProductData--',activeProductData);
      const today = new Date();
      const domainExpiry = new Date(productActive[0].productDetails.due_date);
      const diffTime = domainExpiry.getTime() - today.getTime(); 
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      // console.log('domainExpiry--',domainExpiry);
      if (diffDays <= 7) {
        setRenewActive(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  }
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function Myaccount() {
  const [profileData, setProfileData] = useState([null]);
  const [activeProductData, setActiveProductData] = useState([null]);
  const [productData, setproductData] = useState([null]);
  const [invoiceData, setInvoiceData] = useState();
  const [planData, setPlanData] = useState();
  const [planDetailsArray, setPlanDetailsArray] = useState([]);
  const [renewActive, setRenewActive] = useState(true);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const isadmin = localStorage.getItem("isadmin");
  if(!isadmin){
    navigate("/Self-Service");
  }
  
  const [value, setValue] = React.useState(0);
  useEffect(() => {
    getData(
      setProfileData,
      setproductData,
      activeProductData,
      setActiveProductData,
      setInvoiceData,
      setPlanDetailsArray,
      setRenewActive
    );
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  // console.log('invoiceData--', invoiceData);
  // console.log('activeProductData-arr-',activeProductData);
  const columns = [
    {
      name: "S_No",
      label: "S.NO",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },
    {
      name: "Invoice",
      label: "Invoice",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },
    {
      name: "plan",
      label: "Plan",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },
    {
      name: "date",
      label: "Invoice Date",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },

    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = invoiceData[tableMeta.rowIndex];
          const invoicePdfUrl = rowData.invoicePdfUrl;

          return (
            <>
           
           
              <Button
                title="Print Invoice"
                sx={{ fontWeight: 500 }}
                variant="outline"
                size="small"
                onClick={() => window.open(invoicePdfUrl, "_blank")}
              >
                <img src={printIcon} width={20} />
              </Button>
            </>
          );
        },
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },
  ];

  const addcolumns = [
    {
      name: "s_no",
      label: "S.NO",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },
    {
      name: "plan",
      label: "Plan",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },
    {
      name: "s_date",
      label: "Start Date",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },
    {
      name: "e_date",
      label: "End Date",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },
    {
      name: "users",
      label: "Users",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    rowsPerPage: 5,
    download: true,
    print: true,
  };

  // pagination
  const totalPages = Math.ceil(productData.length / pageSize);

  useEffect(() => {
    paginateData();
  }, [productData, currentPage, pageSize]);

  const paginateData = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const sortedTickets = productData.sort(
      (a, b) => b.case_number - a.case_number
    );
    return sortedTickets.slice(startIndex, endIndex);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(1);
  };

  const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
    const pageNumbers = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1, 2);

      if (currentPage > 5 && currentPage < totalPages - 3) {
        pageNumbers.push("...");
        pageNumbers.push(currentPage - 1, currentPage, currentPage + 1);
        pageNumbers.push("...");
      } else if (currentPage <= 3) {
        pageNumbers.push(3, 4);
        pageNumbers.push("...");
      } else {
        pageNumbers.push("...");
        pageNumbers.push(totalPages - 3, totalPages - 2);
      }
      pageNumbers.push(totalPages - 1, totalPages);
    }

    return (
      <nav>
        <ul className="pagination justify-content-center mt-3 mt-md-0">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &laquo;
            </button>
          </li>
          {pageNumbers.map((number, index) => (
            <li
              key={index}
              className={`page-item ${number === currentPage ? "active" : ""}`}
            >
              {number === "..." ? (
                <span className="page-link">{number}</span>
              ) : (
                <button
                  className="page-link"
                  onClick={() => handlePageChange(number)}
                >
                  {number}
                </button>
              )}
            </li>
          ))}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              &raquo;
            </button>
          </li>
        </ul>
      </nav>
    );
  };
// console.log('totalPages--',totalPages);

  
  return (
    <>
      <Grid className="profile-page-container" container>
        <Grid container sx={{ m: 1 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link className="breadcrumb-home" underline="hover" color="inherit" to="/">
              Home
            </Link>
            <Link className="breadcrumb-home" underline="hover" color="inherit" to="/Self-Service">
              Self Service
            </Link>
            <Typography className="breadcrumb-active" sx={{ color: "text.primary" }}>My Account</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid className="myaccount-container" container sx={{ m: 1 }}>

        <Typography component="div" className="profile-card-image col-lg-2 col-xl-2 col-md-12 col-12 ">
        <Avatar
                  src={UserPng}
                  alt="Profile Image"
                  className="profile-img"
                />
                <img className="profile-upload-img" src={cameraImg} />
            </Typography>
            <Typography component="div" className="col-lg-5 col-xl-5 col-md-12 col-12 personal-info" > 

              <Typography component="div" className="row pers-info-inner" > 
              <Typography component="div" className="col-6 personal-info-line" > 
                <img src={nameIcon} width={20} /> Name  
                </Typography>
                <Typography className="col-6 personal-info-ans"> {profileData.name} </Typography>
                </Typography>

              <Typography component="div" className="row pers-info-inner" > 
              <Typography component="div" className ="col-6 personal-info-line" > 
                <img src={emailIcon} width={20} /> E-Mail  
              </Typography>
              <Typography className="col-6 personal-info-ans"> {profileData.email} </Typography>
              </Typography>

              <Typography component="div" className="row pers-info-inner" > 
              <Typography component="div" className ="col-6 personal-info-line" > 
                <img src={numberIcon} width={20} /> Number  
              </Typography>
              <Typography className="col-6 personal-info-ans"> {profileData.phone_office} </Typography>
              </Typography>

              </Typography>

              <Typography component="div" className="col-lg-5 col-xl-5 col-md-12 col-12 personal-info" > 

              <Typography component="div" className="row pers-info-inner" > 
              <Typography component="div" className="col-6 personal-info-line" > 
                <img src={companyIcon} width={20} /> Company Name  
                </Typography>
                <Typography className="col-6 personal-info-ans"> {profileData.organization_name} </Typography>
                </Typography>

              <Typography component="div" className="row pers-info-inner" > 
              <Typography component="div" className ="col-6 personal-info-line" > 
                <img src={userCountIcon} width={20} /> User  
              </Typography>
              <Typography className="col-6 personal-info-ans"> {activeProductData.number_of_users} </Typography>
              </Typography>



              </Typography>

        </Grid>

        <Grid container sx={{ m: 1 }}>
        <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    className="prof-tab-heading plan-tab"
                    label="Plan Details"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="prof-tab-heading plan-tab"
                    label="Billing Details"
                    {...a11yProps(1)}
                  />

                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                >
                  <Typography component="div" className="plan-details" >
                    <Typography component="div" className="row" >
                    <Typography component="div" className="col-lg-4 col-xl-4 col-12" >
                     <h4>Features</h4>
                    </Typography>
                    <Typography component="div" className="col-lg-8 col-xl-8 col-12" >
                    <Typography component="div" className="row" >
                    <Typography component="div" className="col-lg-6 col-xl-6 col-12 current-package" >
                       <h6> Current Package  </h6> 
                       <h6 className="active-package"> {activeProductData.plantype} </h6>  
                     </Typography>
                    <Typography component="div" className="col-lg-6 col-xl-6 col-12 expiry-package" > 
                      <h6> Expiry  </h6>  
                      <h6 className="active-package"> {formatDate(activeProductData.due_date)} </h6>  
                      </Typography>
                    </Typography>
                      </Typography>
                    </Typography>

                    <Typography component="div" className="row plan-feature" >
                    {planDetailsArray.map((detail, index) => (
                    <Typography component="div" className="col-lg-4 col-xl-4 col-md-6 col-12 feature" >
                        <Typography component="img" src={tickPng} ></Typography>
                        <Typography>{detail}</Typography>
                    </Typography>
                    ))}
                   
                    </Typography>

             

                    </Typography>
                    <Card
                    sx={{ mt: "10px" }}
                    className="mt"
                    size="lg"
                    variant="outlined"
                  >
     
                     <div class="table-responsive">
                    <table className="table text-center">
                    <thead>
                      <tr>
                        <th scope="col">S NO</th>
                        <th scope="col">Plan</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">End Date</th>
                        <th scope="col">Users</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                    { paginateData() && paginateData().length > 1 ? (
                    paginateData().map((product, index) => (
                    <tr>
                      <td>{ product.s_no }</td>
                      <td>{ product.plan }</td>
                      <td>{ product.s_date }</td>
                      <td>{ product.e_date }</td>
                      <td>{ product.users }</td>
                      <td>{ product.status }</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No products available</td>
                  </tr>
                )}
              </tbody>
                    </table>
                    </div>
                    <div className="Pagination">
        <div
          className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-3 mb-3 text-center"
          style={{ textWrap: "nowrap" }}
        >
          <span>Items per page</span>
          <select
            className="form-select table-item-select"
            value={pageSize}
            onChange={handlePageSizeChange}
            style={{ width: "auto" }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
        </div>
        <Pagination
          totalPages={totalPages}
          currentPage={1}
        />
      </div>
                  </Card>
                  </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                  <Box
                  sx={{
                    width: "100%",
                    display: "grid",
                  }}
                >
                  <Card size="lg" variant="outlined">
                    <MUIDataTable
                      title={"Billing Logs"}
                      data={invoiceData}
                      columns={columns}
                      options={options}
                    />
                  </Card>
                </Box>
                  </CustomTabPanel>

          </Box>
          {/* </Box> */}
        </Grid>
       

      </Grid>
    </>
  );
}

export default Myaccount;
