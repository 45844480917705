import React from 'react';
import "../Styles/Footer.css";
import frothLogo from "../Assets/frothRobotico.png";
import linkedin from "../Assets/linkedin.png"
import RightsFooter from './RightsFooter';


const Footer = () => {

  const LinkedIn = () => {
    window.open("https://www.linkedin.com/company/robotico-digital", "_blank");
  };

  const handleDesk = () => {
    window.open("https://frothdesk.com/", "_blank");
  };

  const handleTestops = () => {
    window.open("https://frothtestops.com", "_blank");
  };

  const termsCondition = () => {
    window.open('/TermsAndConditions', '_blank');
  };

  const handleCrm = () => {
    window.open('/', '_blank');
  };

  const privacyPolicy = () => {
    window.open('/PrivacyPolicy', '_blank');
  };

  return (
    <>
    <div className="text-bg-dark p-3 footer-contain">
    <img src={frothLogo} alt="FrothCRM Logo" style={{ height: '2rem',paddingLeft:'3rem' }} />
     <div className='Footer-Content d-flex justify-content-between'>
      <div className='cardFooter'>
      <div className='subCardFooter'>
        <div className='productLink' onClick={handleDesk}>Desk</div>
        <div className='productLink' onClick={handleCrm}>CRM</div>
        <div className='productLink' onClick={handleTestops}>TestOps</div>
      </div>
      <div className='subCardFooter'>
        <div className='productLink' onClick={privacyPolicy}>Privacy</div>
        <div className='productLink' onClick={termsCondition}>Terms</div>
        <div></div>
      </div>
     </div>
     <div className='cardFooter'>
     <div className='subCardFooter'>
        <div style={{cursor:'text'}}>Social Links</div>
        <img src={linkedin} alt="FrothCRM Logo" onClick={LinkedIn} style={{ height: '1.3rem' }} />
        <div></div>
      </div></div>
     </div>
    </div>
    <RightsFooter />
    </>
  );
};

export default Footer;
